import { useState } from 'react'
import Header from "./components/Header"
import Tasks from './components/Tasks'
import AddTask from './components/AddTask'

function App() {
  const name = "John Doe"
  const [showAddTask, setShowAddTask] = useState(false)
  const [tasks, setTasks] = useState([
    {
        id: 1,
        text: "Task Nummer Eins",
        date: "2021-03-23",
        reminder: false,
    },
    {
        id: 2,
        text: "Task Nummer Zwei",
        date: "2021-03-29",
        reminder: false,
    },
    {
        id: 3,
        text: "Task Nummer Drei",
        date: "2021-04-21",
        reminder: true,
    },
])
  // Add Task
  const addTask = (task) => {
    const id = Math.floor(Math.random()*10000 + 1)
    console.log(id)
    const newTask = {id, ...task }
    setTasks([...tasks, newTask])
  }
  // Delete
  const deleteTask = (id) => {
    console.log("delete", id)
    setTasks(tasks.filter((task) => task.id !== id))
  }
  // Toggle Reminder
  const toggleReminder = (id) => {
    setTasks(tasks.map((task) => task.id === id ? 
    { ...task, reminder: !task.reminder } : task))
  }
  return (
    <div className="container">
      <Header title={"Task Tracker"} onAdd={() => setShowAddTask(!showAddTask)} showAdd={showAddTask} />
      {showAddTask && <AddTask onAdd={addTask}/>}
      {tasks.length > 0 ?
      <Tasks tasks={tasks} onDelete={deleteTask} onToggle={toggleReminder}/> :
      "Keine Aufgaben da."}
    </div>
      
  );
}

export default App;
