import { useState } from 'react'


function AddTask({onAdd}) {
        const [text, setText] = useState('')
        const [date, setDay] = useState('')
        const [reminder, setReminder] = useState(false)
        const onSubmit = (e) => {
            e.preventDefault()

            if(!text) {
                alert("Text ist leer.")
                return
            }
            onAdd({text, date, reminder})
            setText('')
            setDay('')
            setReminder(false)

        }

    return (
        <form className='add-form' onSubmit={onSubmit}>
            <div className='form-control'>
                <label>task</label>
                <input type='text' placeholder='Add Task' value={text} onChange={(e)=> setText(e.target.value)}/> 
            </div>
            <div className='form-control'>
                <label>day & time</label>
                <input type='text' placeholder='Add Day & Time' value={date} onChange={(e)=> setDay(e.target.value)}/> 
            </div>
            <div className='form-control form-control-check'>
                <label>Set Reminder</label>
                <input type='checkbox' checked={reminder} value={reminder} onChange={(e)=> setReminder(e.currentTarget.checked)}/> 
            </div>
            <input type='submit' value='Save Task' className='btn btn-block'/>
        </form>
    )
}

export default AddTask
