import PropTypes from 'prop-types'
import Button from './Button'


const Header = ({title, onAdd, showAdd}) => {
    // const doThis = (e) => {
    //     console.log("Hellau again")
    // }
    return (
        <>
        <header className='header'>
            <h1  style={headingStyle} >{title} </h1>
            <h2 style={{color:"#aaa"}}>React App </h2>
            <Button text={showAdd ? 'Close' : 'Add'} color='grey' onClick={onAdd}/>
        </header>
        
        </>
    )
}


Header.defaultProps = {
    title: "Task Tracker",
}

Header.propTypes = {
    title: PropTypes.string.isRequired,
}

const headingStyle = {
    fontWeight: "bolder",
    color: "grey",
}

export default Header
